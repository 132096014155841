
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import axios from "axios";
import { useStore } from "vuex";
import {mask} from 'vue-the-mask'
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import JwtService from "@/core/services/JwtService";
import EditProductCategoriesModal from "@/components/modals/forms/EditProductCategoriesModal.vue";
import { Modal } from "bootstrap";


export default defineComponent({
  name: "add-standard-categories-modal", 
  directives: {mask}, 
  props: {  
    id: { required: true }  
    // data: {
    //     type: Object
    // }
  },
  
  components: {    
  }, 
  // created () {
  //       const field = document.querySelector("input[name=test]").value
  //       console.log(field)
  // }
       
  setup(props) {    
    const formRef = ref<null | HTMLFormElement>(null);
    const addStandardCategoryModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);    

    const loadingStandard = ref<boolean>(false);
    const router = useRouter();    
    //var formData = ref({})
    const store = useStore();
    var mapping_list: any[] = []
    const std = ref([]);
    //const inputValue = new Modal(document.getElementById("cat_id"));

    const formData = ref({
      category_id: "",
      attribute_select: "",
      uom_select: "",
      min_select: "",
      max_select: "",
    }); 
   
    const attribute = ref([]);
    const getAttributeList = async (data) => {
     
      try {

        var values = { "attribute_id"  : 0,"page"  : 1, "records_per_page" : 100}
        await store.dispatch(Actions.CUST_GET_PRODUCT_ATTRIBUTE_LIST, values).then(({ data }) => {
        attribute.value = data.result_list;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const uom = ref([]);
    const setUomData = async (data) => {
      try {

        await store.dispatch(Actions.CUST_ADD_UOM_LIST).then(({ data }) => {
        uom.value = data;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

   
    const setLinkData = async (standard_data) => { 
      var user = JSON.parse(JwtService.getToken());
        
          var category_id = ((document.querySelector("input[name=cat_id]") as HTMLInputElement).value);
          
          const db_data = {
            "category_id": category_id,
            "attribute_id": standard_data.attribute_select,
            "uom_id": standard_data.uom_select,
            "min_value": standard_data.min_select,
            "max_value": standard_data.max_select,
            "created_user_id": 1,        
          }
            
        await store.dispatch(Actions.CUST_LINK_ATTRIBUTE_RANGE, db_data).then(({ data }) => {

        if (data){
            
            setTimeout(() => {
              loading.value = false;
              hideModal(addStandardCategoryModalRef.value);
              Swal.fire({
                text: "Product Standard mapped successfully.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addStandardCategoryModalRef.value);   
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });
    
    }


    onMounted( async () => {      
      await getAttributeList(attribute.value)
      await setUomData("")
      });  

    
    const rules = ref({
      attribute_select: [
        {
          required: true,
          message: "Attribute is required",
          trigger: "change",  
             
        },  
      ],  
      
    });


   const submit = async () => {

      loading.value = true;

      if (!formRef.value) {
        loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {

        if (valid) {

          await setLinkData(formData.value);
        
        } else {

          loading.value = false;
          return false;
        }

      });  
    }
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      std,
      getAttributeList,
      setUomData,
      uom,
      attribute,
      //getStandardData,
      addStandardCategoryModalRef,
    };
  },
});
