
import { defineComponent, ref, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {designation_list} from "@/core/data/genericData";
import axios from "axios";
import JwtService from "@/core/services/JwtService";
import {mask} from 'vue-the-mask'
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import { useRouter } from "vue-router";
import { Modal } from "bootstrap";
import { number, string } from "yup/lib/locale";


export default defineComponent({
  name: "add-branch-modal",
  directives: {mask},
  props: {    
    id: { required: true }
  },
  components: {
    
  },
  methods: {
    onChange() 
    { 
      this.formData.attribute_select = '' 
    }
  },

  // ShowHide(){
  //       if(this.formData.value_select == 'add')
  //       {
  //         console.log("innnnnnnnnnnnnnnnnnnnnn");
  //         (document.querySelector("#value_lable") as HTMLInputElement).style.display = "block";
  //         const modal = new Modal(document.getElementById("newtxt"));
  //         modal.show();
  //       }
  //       else
  //       {
  //         const modal = new Modal(document.getElementById("newtxt"));
  //         modal.hide();
  //         (document.querySelector("#value_lable") as HTMLInputElement).style.display = "none";        
  //       }
  // },

  setup(props) {
    
    const store = useStore();
    const designation_item = designation_list;    
    const formRef = ref<null | HTMLFormElement>(null);
    const addAttributeModalRef = ref<null | HTMLElement>(null);
    const loading = ref<boolean>(false);
    const router = useRouter();    
    const loadingData = ref<boolean>(true);
    const attribute = ref([]);
    const formData = ref({
      attribute_type_select: "",
      attribute_select: "",
      std_select: "",
      uom_select: "",
      new: "",
      category_id: "",

    }); 
    const uom_value = ref([])   
    const attr_value = ref([])
    const attrtype_value = ref([])
   
    const rules = ref({
      attribute_type_select: [
        {
          required: true,
          message: "Attribute type is required",
          trigger: "change",          
        },        
      ],
        attribute_select: [
        {
          required: true,
          message: "Attribute is required",
          trigger: "change",          
        },        
      ],
      std_select: [
        {
          required:true,
          message: "Standard is required",
          trigger: "change",          
        },    
      ],
      new: [
        {
          required: true,
          message: "Attribute Value is required",
          trigger: "change",          
        },        
      ],
    });

    var paginationData = ref({})
    
    paginationData.value = {      
        start : 0,
        end : 0,
        total: 0,        
        perPage : 5,
        activePage : 1,
        totPage : 1,        
        pageRange : [1]
    }

    onMounted( async () => {
      await getAttributeList("")
      await setUomData("")
      await setStandardData(standard.value)
    });

     const OnClickAttribute = async(attrid) => {
      console.log(" Attribute Id")
      console.log(attrid)
      for (var i = 0; i < attribute.value.length; i++)
      {
        if(attribute.value[i]['attribute_type'] == attrid){
          attrtype_value.value = attribute.value[i]['attribute_type']
          attr_value.value = attribute.value[i]['attribute_list']
        }
      }
      // if(formData.value.attribute_type_select == '')
      // {
      //   const modal = new Modal(document.getElementById("attrtxt")).value = ''; 
      // }
      
    } 


    const standard = ref([]);
    const setStandardData = async (data) => {
        loading.value = true;
        try {
          var values = {"search_term" : data}
          await store.dispatch(Actions.CUST_GET_GRADE_STANDARD, values).then(({ data }) => {
          standard.value = data.page_data;
          console.log(standard.value)
          loading.value = false;
         
        })
        .catch(({ e }) => {
          console.log(e);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

    const setUomData = async (data) => {
      try {

        await store.dispatch(Actions.CUST_ADD_UOM_LIST).then(({ data }) => {
        uom_value.value = data;
          
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }
    }

     const getAttributeList = async (data) => {
      
      loadingData.value = true;
    
      try {
        var values = {}
        await store.dispatch(Actions.CUST_ADD_ATTRIBUTE_LIST, values).then(({ data }) => {
        attribute.value = data.attribute_type;
        })
        .catch(({ response }) => {
          console.log(response);        
        });
        
      } catch (e) {
        console.log(e);
      }

    }

     const setAttributeLinkData = async (data) => {

      var category_id = ((document.querySelector("input[name=cat_id]") as HTMLInputElement).value);
      console.log(category_id);
      let uom = 0
      if (!data.uom_select)
      {
        uom = 0
         
      }
      else{
        uom = data.uom_select
      }
      const db_data = {
        "category_id": category_id,
        "attribute_id": data.attribute_select,
        "attribute_type_id": data.attribute_type_select,
        "attribute_val_id": data.std_select,
        "attribute_value": data.new || uom,    
        "attribute_uom_id": uom,    
        }
        await store.dispatch(Actions.CUST_MAPPING_ATTRIBUTE, db_data).then(({ data }) => {
          if (data){
        
            setTimeout(() => {
              loading.value = false;
             hideModal(addAttributeModalRef.value);
              Swal.fire({
                text: "Attribute has been successfully mapped.",
                icon: "success",
                buttonsStyling: false,
                confirmButtonText: "Ok, got it!",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
              }).then(() => {
                hideModal(addAttributeModalRef.value);  
                router.go(0)           
              });
            }, 2000)          

          } else {

            loading.value = false;

            Swal.fire({
              text: "Sorry, looks like there are some errors detected, please try again.",
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Ok, got it!",
              customClass: {
                confirmButton: "btn btn-primary",
              },
            })  
            return false;
          }
      
      })
      .catch(({ response }) => {
        console.log(response);        
      });

    }

    const submit = async () => {
      
      loading.value = true;

      if (!formRef.value) {
        //loading.value = false;
        return;
      }

      formRef.value.validate (async (valid) => {
        if (valid) {
          await setAttributeLinkData(formData.value);
        } else {
          loading.value = false;
          return false;
        }
      });
    };   
    
    return {
      formData,
      rules,
      submit,
      formRef,
      loading,
      attribute,
      addAttributeModalRef,
      getAttributeList,
      OnClickAttribute,
      setUomData,
      setStandardData,
      standard,
      attr_value,
      attrtype_value,
      uom_value
    };
  },
});
